<template>
    <div class="Products full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 pt-5 flex-grow-1 bg-white ">
                <v-row class="d-flex align-center mb-5 filter">
                    <v-col cols="5" class="pl-0 d-flex align-center">
                        <p>{{ $t('general.status') }}:</p>
                        <v-select :items="filterStatus" outlined hide-details="auto" v-model="filterStatusSelected" attach class="ml-6"/>
                    </v-col>
                </v-row>

                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="productsTableHeaders"
                    :items="filteredList"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.id" @click="editRow(item)">
                            <td>{{ item.productName }}</td>
                            <td>{{ item.number }}</td>
                            <td>{{ findMethod(item.method) }}</td>
                            <td>{{ findStatus(item.status) }}</td>
                            <td :class="{edit: item.editable && checkUserRights('productsEdit'), show: !item.editable || !checkUserRights('productsEdit')}"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="printAll" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printAll" :class="{'disabled': !checkUserRights('productsPrint')}"></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newProduct" :class="{'disabled': !checkUserRights('productsEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'Products',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            filterStatusSelected: 'show all',
            showPrintProgress: false,
            showPrintSuccess: false,
        }
    },
    computed: {
        ...mapState([
            'productlist',
            'methodlist',
        ]),
        productsTableHeaders(){
            return [
                { text: this.$t('general.name'), align: 'start', value: 'productName' },
                { text: this.$t('products.number'), align: 'start', value: 'number' },
                { text: this.$t('methods.method'), align: 'start', value: 'method' },
                { text: this.$t('general.status'), value: 'status' },
                { text:"", sortable: false,}
            ]
        },
        filteredList(){
            let filteredlist = this.$store.state.productlist;
            if (this.filterStatusSelected == this.$t('filter.showActive')){
                filteredlist = filteredlist.filter(function(product){
                    return product.status == true;
                })
            } else if (this.filterStatusSelected == this.$t('filter.showInactive')){
                filteredlist = filteredlist.filter(function(product){
                    return product.status == false;
                })
            }
            return filteredlist;
        },
        filterStatus(){
            return [
                this.$t('filter.showAll'),
                this.$t('filter.showActive'),
                this.$t('filter.showInactive'),
            ]
        },
    },
    methods: {
        findStatus(statusValue){
            let status = this.$t('general.active')
            if (statusValue === false){
                status = this.$t('general.inactive')
            }
            return status;
        },
        findMethod(methodID){
            let methodName;
            let method = this.methodlist.find(function(element){
                return element.id == methodID;
            });
            if (method){
                methodName = method.methodName;
            } else {
                methodName = this.$t('general.unknown')
            }
            if (methodID == 0){
                methodName = '–'
            }
            return methodName;
        },
        printAll(){
            this.showPrintProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/disi/products/printall')
                .then(response =>{
                    this.showPrintProgress = false;
                    if (response.status === 200){
                        this.showPrintSuccess = true;
                        setTimeout(()=>{
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        editRow(product){
            this.$store.commit('ChangeEditProduct', product);
        },
        newProduct(){
            let product = {
                id: null,
                productName: "",
                number: "",
                method: null,
                editable: true,
                status: true
            }
            this.$store.commit('ChangeEditProduct', product);
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/disi/methods/list', 'methodlist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/media/list', 'medialist']);
        this.$store.dispatch('getAxiosSetter', ['/disi/products/list', 'productlist']);
    },
}
</script>
<style scoped lang="scss">
</style>